import { Button, Popover, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color';

const ScaleRange = ({
  minMaxScale, rangeObj, setRangeobj, closePopover, scaleSplit, setScaleSplit,
}) => {
  const [editItem, setEditItem] = useState({})
  const defaultColors = ['#489558', '#B1D778', '#DEED97', '#FAE096', '#d9a81c', '#E4754F', '#C74032', '#ec3913', '#b81f0b']
  const [popOverOpen, setPopOverOpen] = useState(false)
  const [tempRangeObj, setTempRangeObj] = useState([])
  const [tempSplit, setTempSplit] = useState()
  const randomHexColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i += 1) {
      color += letters[(Math.floor(Math.random() * 16))];
    }
    return color
  }
  useEffect(() => {
    setTempRangeObj(rangeObj)
    setTempSplit(scaleSplit)
  }, [])

  const opitons = [
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
  ]

  const handleColorOnChange = (e) => {
    setEditItem({ ...editItem, color: e.hex })
    const index = tempRangeObj.findIndex((itm) => itm.id === editItem.id)
    const temp = tempRangeObj
    temp[index].color = e.hex
    setTempRangeObj(temp)
  }

  const handleSplitChange = (val) => {
    setTempSplit(val)
    setTempRangeObj([])
    let lowerBound = 0
    let upperBound = 0
    let id = 0
    const temp = []
    const diff = Math.round((minMaxScale.max - minMaxScale.min) / val)
    while (upperBound <= minMaxScale.max) {
      upperBound = lowerBound + diff
      const tempObj = {
        id,
        lowerBound,
        upperBound,
        color: defaultColors[id] ? defaultColors[id] : randomHexColor(),
      }
      temp.push(tempObj)
      lowerBound = upperBound + 1
      id += 1
    }
    setTempRangeObj(temp)
  }

  return (
    <div className="scale-range-edit-container">
      <div className="scale-range-edit-split-selector">
        Total number of splits <Select value={tempSplit} options={opitons} style={{ width: 20 }} onChange={(value) => handleSplitChange(value)} />
      </div>
      {tempRangeObj.map((itm) => (
        itm.id === editItem.id ? (
          <div className="scale-range-edit-section">
            <div className="scale-range-edit-row">
              <Popover
                overlayClassName="scale-range-clr-popover"
                content={<SketchPicker color={editItem.color} onChangeComplete={(e) => handleColorOnChange(e)} />}
                open={popOverOpen}
                onOpenChange={() => { setPopOverOpen(false); setEditItem({}) }}
                className="edit-scale-range-color-popover"
              >
                <div className="scale-range-edit-color-picker" style={{ backgroundColor: editItem.color }} role="presentation" onClick={() => { setEditItem(itm); setPopOverOpen(true) }}>
                  <div className="picker-icon" />
                </div>
              </Popover>
              <div className="scale-range-edit-min-max-sec">
                <div className="scale-range-edit-min-value">
                  {itm.lowerBound}
                </div>
                <div className="scale-range-edit-min-to">To</div>
                <div className="scale-range-edit-max-value">
                  {itm.upperBound}
                </div>
              </div>
              {/* <div className="scale-range-edit-delete-button">
                <Button className="del-icon" title="Delete" />
              </div> */}
            </div>
          </div>
        )
          : (
            <div className="scale-range-edit-section">
              <div className="scale-range-edit-row">
                <div className="scale-range-edit-color-picker" style={{ backgroundColor: itm.color }} role="presentation" onClick={() => { setEditItem(itm); setPopOverOpen(true) }}>
                  <div className="picker-icon" />
                </div>
                <div className="scale-range-edit-min-max-sec">
                  <div className="scale-range-edit-min-value">
                    {itm.lowerBound}
                  </div>
                  <div className="scale-range-edit-min-to">To</div>
                  <div className="scale-range-edit-max-value">
                    {itm.upperBound}
                  </div>
                </div>
                {/* <div className="scale-range-edit-delete-button">
                  <Button className="del-icon" title="Delete" />
                </div> */}
              </div>
            </div>
          )
      ))}
      <div className="scale-range-footer-section">
        <div className="scale-range-reset-button">
          <Button onClick={() => { setTempRangeObj(rangeObj); setTempSplit(scaleSplit) }}>Reset</Button>
        </div>
        <div className="scale-range-update-button">
          <Button onClick={() => {
            setRangeobj(tempRangeObj);
            setScaleSplit(tempSplit);
            closePopover(false)
          }}
          >Update
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ScaleRange
